<template>
  <section>
    <title-section
      title="Agregar producto"
      returnText="Regresar"
      :returnToFunction="returnPrevView"
    >
      <template>
        <div class="level-item">
          <button class="button button_micro_dark_outlined" @click="returnPrevView" :disabled="loadingBtn">
            CANCELAR
          </button>
        </div>
        <div class="level-item">
          <button
            class="button button_micro_dark"
            :disabled="loadingBtn"
            :class="{ 'is-loading' : loadingBtn }"
            @click="createProductAction()"
          >
            AGREGAR
          </button>
        </div>
      </template>
    </title-section>
    <template v-if="loading">
      <Loading />
    </template>
    <template v-else>
      <product-main-add
        ref="productMainAddRef"
        :brandListParent="brandList"
        :sectorsListParent="sectorsList"
        :linesListParent="linesList"
        :currencyListParent="currencyList"
        :loadingCatalog="loadingCatalog"
        :loadingBtn="loadingBtn"
      />
    </template>
  </section>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ProductsAdd',
  components: {
    TitleSection: () => import('@/components/UI/TitleSection.vue'),
    ProductMainAdd: () => import('@/components/Products/ProductMainAdd.vue'),
    Loading: () => import('@/components/UI/Loading.vue')
  },
  data () {
    return {
      loading: false,
      loadingCatalog: false,
      loadingBtn: false,
      brandList: [],
      sectorsList: [],
      linesList: [],
      currencyList: []
    }
  },
  methods: {
    ...mapActions([
      'returnPrevView',
      'getProductBrandSelect',
      'getProductSectorSelect',
      'getProductLineWithSublines',
      'getCurrency',
      'createProduct',
      'addTemporalNotification',
      'getAllBrandsCatalog'
    ]),
    async createProductAction () {
      const dataProduct = await this.$refs.productMainAddRef.getDataProduct()
      if (dataProduct) {
        this.loadingBtn = true
        const resp = await this.createProduct(dataProduct)
        if (resp) {
          this.returnPrevView()
        }
      } else this.addTemporalNotification({ type: 'danger', message: 'Ingrese los campos requeridos' })
      this.loadingBtn = false
    }
  },
  async beforeMount () {
    this.loading = true
    this.loadingCatalog = true
    const dataBrands = await this.getAllBrandsCatalog()
    if (dataBrands.success) {
      this.brandList = dataBrands.brands
      console.log(this.brandList)
    }
    const dataSectors = await this.getProductSectorSelect()
    if (dataSectors.success) {
      this.sectorsList = dataSectors.sectors
    }
    const dataLines = await this.getProductLineWithSublines()
    if (dataLines.success) {
      this.linesList = dataLines.lines
    }
    const dataCurrency = await this.getCurrency()
    if (dataCurrency.success) {
      this.currencyList = dataCurrency.currency
    }
    this.loading = false
    this.loadingCatalog = false
  }
}
</script>

<style lang="scss" scoped>
  .button {
    font-size: 14px;
    width: 150px;
  }
</style>
